<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small" @submit.native.prevent>
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" @keyup.enter.native="query"/>
				</el-form-item>
				<el-form-item label="状态" label-width="50px" prop="status">
				  <el-select v-model="queryForm.status" placeholder="请选择状态" size="small" class="w-100">
				     <el-option label="全部" value=""></el-option>
				     <el-option label="开票申请" value="2"></el-option>
				     <el-option label="已开票" value="3"></el-option>
				  </el-select>
				</el-form-item>
				<el-form-item label="时间" label-width="50px">
				  <el-date-picker
				   size="mini"
				   value-format="yyyy-MM-dd"
				   v-model="queryForm.time"
				   type="daterange"
				   range-separator="至"
				   start-placeholder="开始日期"
				   end-placeholder="结束日期">
				   </el-date-picker>
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<!-- <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button> -->
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
            <!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ (queryForm.page - 1) * queryForm.limit + scope.$index + 1 }}
				  </template>
				</el-table-column>
				<el-table-column label="单位名称" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="记录" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.desc }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="缴费凭证" min-width="110" align="center">
				  <template slot-scope="scope">
					   <img :src="item.url" style="width: 30px;height: 30px;cursor: pointer;margin-right: 5px;" v-for="(item,index) in scope.row.album" @click="viewImage(item,'url')" v-if="scope.row.album.length > 0">
					   <img src="@/assets/images/empty.png" style="width: 30px;height: 30px;cursor: pointer;" v-else>
				  </template>
				</el-table-column>
				<el-table-column label="发票地址" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.url }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="发票图片" min-width="110" align="center">
				  <template slot-scope="scope">
					  <img :src="scope.row.invoice" style="width: 35px;height: 35px;" @click="viewImage(scope.row,'invoice')" v-if="scope.row.invoice">
					  <img src="@/assets/images/empty.png" style="width: 35px;height: 35px;" v-else>
				  </template>
				</el-table-column>
				<el-table-column label="状态" min-width="60" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 1" size="mini" type="danger">待审核</el-tag>
					<el-tag v-if="scope.row.status === 2" size="mini" type="warning">申请发票</el-tag>
					<el-tag v-if="scope.row.status === 3" size="mini" type="success">已开票</el-tag>
					<el-tag v-if="scope.row.status === 4" size="mini" type="danger">不通过</el-tag>
					<el-tag v-if="scope.row.status === 5" size="mini" type="success">已审核</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="160">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.ctime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="审核" placement="top" v-if="scope.row.status == 1">
					  <el-button type="warning" icon="el-icon-check" size="mini" circle @click="handleOperate(scope.row)" ></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="发票" placement="top" v-if="scope.row.status == 2">
					  <el-button type="warning" icon="el-icon-tickets" size="mini" circle @click="handleFp(scope.row)"></el-button>		
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<el-dialog :visible.sync="fp" width="600px" :close-on-click-modal="false" append-to-body :title="title">
			<el-form ref="fpForm" :model="fpForm" label-width="80px" size="small">
			    <el-form-item  label="发票地址">
					<el-input v-model="fpForm.url" placeholder="请输入发票地址" type="text" clearable />
			    </el-form-item>
				<el-form-item label="发票图片" prop="invoice">
				  <el-upload
					class="upload-border"
					action="/manage/images/upload"
					:show-file-list="false"
					:on-success="uploadSuccess"
					:before-upload="beforeUpload">
					<img v-if="fpForm.invoice != ''" :src="fpForm.invoice" class="w-100 h-100">
					<i v-else class="el-icon-plus uploader-icon"></i>
				  </el-upload>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
			  <el-button size="mini" @click="fpCancel">取 消</el-button>
			  <el-button type="primary" size="mini" @click="handleFpSubmit">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'jfjl',
				autoRequest: false,
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
					status:'',
					time:'',
					tab_index: '',
				},
				fp:false,
				fpForm:{
					id:'',
					url:'',
					invoice:'',
				},
				page: {
					page: 1,
					limit: 10,
					total:0,
				},
				file:{},
			}
		},
		mounted() {
			this.getList()
		},
		methods:{
			getList() {
			  this.loading = true
			  this.axios.get(`/manage/${this.preUrl}/invoice`, {
			    params: this.queryForm
			  }).then(res => {
			    this.total = parseInt(res.total)
			    this.list = res.data
			    this.loading = false
			  }).catch(err => {
			    this.loading = false
			  })
			},
			handleFp(row){
				this.fp = true
				this.title = '发票'
				this.fpForm.id = row.id
			},
			fpCancel(){
				this.fp = false
				this.fpForm = this.$options.data().fpForm
			},
			handleFpSubmit(){
				this.axios.post('/manage/'+this.preUrl + '/fp', this.fpForm).then(res => {
				  if (res.status) {
					this.$message.success('添加发票成功')
					this.fp = false
					this.getList()
				  } else {
					this.$message.error(res.msg)
				  }
				})
			},
			uploadSuccess(e){
			   if(e.status){
				 this.fpForm.invoice = e.data.url
			   }
			},
			beforeUpload(){},
		}
	}
</script>

<style>
</style>